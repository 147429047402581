import React, { useState, useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  const [isVideoPlaying] = useState(false);
  const typedElement = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        'Unlock Silent Communication with AI Lip Reading.',
        'Enhance Accessibility with Real-time Lip Reading.',
        'Communicate Seamlessly without Speaking.'
      ],
      typeSpeed: 30,
      backSpeed: 30,
      backDelay: 4000,
      startDelay: 200,
      loop: true,
    };

    const typed = new Typed(typedElement.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  const contactViaEmail = () => {
    window.location.href = 'mailto:chris@symphoniclabs.com?subject=Inquiry%20about%20AI%20Lip%20Reading%20Solutions';
  };

  return (
    <div className={`hero-container ${isVideoPlaying ? 'dimmed' : ''}`}>
      <video src={process.env.PUBLIC_URL + '/videos/vid2.mp4'} autoPlay loop muted />
      <h1>AI Lip Reading Technology</h1>
      <p ref={typedElement}></p>

      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={contactViaEmail}
        >
          Contact Us for AI Lip Reading Solutions
        </Button>
        {/* Uncomment for future demo functionality */}
        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={redirectToDemo}
        >
          Watch Demo <i className='far fa-play-circle' />
        </Button> */}
      </div>
      <div id='trailer-container'></div>
    </div>
  );
}

export default HeroSection;
