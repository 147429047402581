import React from 'react';
import './Button.css';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({ children, type, onClick, buttonStyle, buttonSize, href }) => {
  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const buttonClass = `btn ${checkButtonStyle} ${checkButtonSize}`;

  if (href) {
    // If an href prop is provided, render an anchor tag
    return (
      <a href={href} className={buttonClass} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  // Otherwise, render a button tag
  return (
    <button className={buttonClass} onClick={onClick} type={type}>
      {children}
    </button>
  );
};