import React from 'react';
import './SponsorSection.css'; // Create a new CSS file for styling

function SponsorSection() {
  return (
    <div className='sponsor-container'>
      <h1>Backed By</h1>
      {/* Add your sponsor logos or content here */}
      <div className='sponsor-logos'>
        {/* Add individual sponsor logos as needed */}
        <img src={process.env.PUBLIC_URL + '/sponsors/aigrant.png'} alt='AI Grant' />
        <img src={process.env.PUBLIC_URL + '/sponsors/spc.webp'} alt='South Park Commons' />
        <img src={process.env.PUBLIC_URL + '/sponsors/velocity.png'} alt='Velocity' />
        <img src={process.env.PUBLIC_URL + '/sponsors/buildspace.png'} alt='Buildspace' />
        <img src={process.env.PUBLIC_URL + '/sponsors/uwaterloo.png'} alt='uwaterloo' />
        {/* Add more sponsor logos as needed */}
      </div>
    </div>
  );
}

export default SponsorSection;